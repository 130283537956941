.ue-prep-box {
    padding: 24px;
}

.ue-prep-row {
    display: flex;
    position: relative;
    width: 100%;
}

.ue-prep-type-box {
    padding: 16px;
    width: 80px;
    flex-shrink: 0;
    border: 2px solid black;
    margin: 12px 0;
    border-radius: 24px 0 0 24px;
    text-align: center;
}

.ue-prep-steps-box {
    background-color: white;
    border-radius: 0 24px 24px 0;
    padding: 12px;
    flex-grow: 1;
    margin: 12px 0 12px 0;
    border: 2px solid #2CC0DB;
}

.ue-prep-child-icon {
    font-size: 2.1em;
    margin: 12px auto 0 auto;
    padding-top: 12px;
}

.ue-prep-adult-icon {
    font-size: 2.6em;
    margin: auto;
    padding-top: 12px;
}

.ue-prep-type-label {
    text-align: center;
}

@media all and (max-width: 600px) {
    .ue-prep-row {
        flex-direction: column;
        align-items: stretch;
    }

    .ue-prep-type-box {
        border-radius: 24px 24px 0 0;
        width: auto;
        margin: 12px 0 0 0;
        padding: 0;
    }

    .ue-prep-steps-box {
        border-radius: 0 0 24px 24px;
        margin: 0 0 12px 0;
    }
}