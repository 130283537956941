@import url("https://fonts.googleapis.com/css2?family=KoHo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Rosarivo:ital@0;1&display=swap");

body {
    height: 100%;
    margin: 0;
    font-family: "Lato", sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 150%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
    text-align: left;
    margin: 24px 0;
    font-family: "KoHo", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 3em;
    line-height: 130%;
}

h2 {
    text-align: left;
    margin: 24px 0;
    font-family: "KoHo", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2em;
    line-height: 130%;
}

h3 {
    text-align: left;
    margin: 12px 0;
    font-family: "KoHo", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.5em;
    line-height: 140%;
}

h4 {
    text-align: left;
    margin: 12px 0;
    font-family: "KoHo", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.1em;
}