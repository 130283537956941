.ue-ahb-container {
    border-radius: 24px 24px 0 0;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-gap: 24px;
}

.ue-ahb-column {
    padding: 0;
    margin: 0;
    order: 1;
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-between;
}

.ue-ahb-title-container {
    border-radius: 24px 0 24px 0;
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: black;
    -webkit-box-shadow: 4px 4px 0px 5px #000000;
    box-shadow: 4px 4px 0px 5px #000000;
    padding: 0 24px;
    margin: 0;
}

.ue-ahb-image {
    width: calc(100% - 48px);
    margin: auto;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    order: 2;
}



.ue-ahb-text {
    padding: 24px 24px 0 24px;
    font-size: 1.2em;
    line-height: 170%;
}

.ue-ahb-user-type {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
}

.ue-ahb-child-icon {
    font-size: 2.2em;
    margin-top: auto;
}

.ue-ahb-adult-icon {
    font-size: 2.6em;
    margin-top: auto;
}

.ue-ahb-user-type-label {
    margin: auto 12px 0 12px;
}

@media all and (max-width: 750px) {
    .ue-ahb-container {
        grid-template-columns: 100%;
    }

    .ue-ahb-title-container {
        border-radius: 24px 24px 0 0;
    }

    .ue-ahb-title {
        font-size: 2.4em;
    }

    .ue-ahb-user-type-label {
        font-size: 1.8em;
    }

    .ue-ahb-image {
        padding: 0;
        width: calc(70% - 48px);
    }
}

@media all and (max-width: 500px) {
    .ue-ahb-container {
        grid-template-columns: 100%;
    }

    .ue-ahb-title-container {
        border-radius: 24px 24px 0 0;
    }

    .ue-ahb-title {
        font-size: 2em;
    }

    .ue-ahb-user-type-label {
        font-size: 1.2em;
    }

    .ue-ahb-child-icon {
        font-size: 1.5em;
    }

    .ue-ahb-adult-icon {
        font-size: 1.7em;
    }
}