.App {
    text-align: center;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly
}

.table-responsive {
  max-height: 39rem !important;
}

.tableColumn {
  word-break: break-all
}

#root {
    background-color: #CDEBEA;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.ue-small-shadow {
    -webkit-box-shadow: 2px 2px 0px #000000;
    box-shadow: 2px 2px 0px #000000;
}

.ue-mid-shadow {
    -webkit-box-shadow: 5px 5px 0px #000000;
    box-shadow: 5px 5px 0px #000000;
}

.ue-big-shadow {
    -webkit-box-shadow: 8px 8px 0px #000000;
    box-shadow: 8px 8px 0px #000000;
}

.ue-small-inner-shadow {
    -webkit-box-shadow: inset 2px 2px 0px #000000;
    box-shadow: inset 2px 2px 0px #000000;
}

.ue-mid-inner-shadow {
    -webkit-box-shadow: inset 5px 5px 0px #000000;
    box-shadow: inset 5px 5px 0px #000000;
}

.ue-main-content {
    flex: 1;
    padding: 24px;
    max-width: 1000px;
    margin: 12px auto; /* Center the content horizontally */
}

@media all and (max-width: 750px) {
    .ue-main-content {
        padding: 6px;
    }
}

.ue-primary-button {
    padding: 12px 32px;
    background-color: #FC9E36;
    border: 2px solid black;
    cursor: pointer;
    border-radius: 24px;
    transition: all ease 0.3s;
    -webkit-box-shadow: 5px 5px 0px #000000;
    box-shadow: 5px 5px 0px #000000;
    font-weight: 600;
}

    .ue-primary-button:hover {
        background-color: white;
        -webkit-box-shadow: 1px 1px 0px #000000;
        box-shadow: 1px 1px 0px #000000;
    }

.ue-secondary-button {
    padding: 12px 32px;
    background-color: white;
    border: 2px solid black;
    cursor: pointer;
    border-radius: 24px;
    transition: all ease 0.3s;
    -webkit-box-shadow: 3px 3px 0px #000000;
    box-shadow: 3px 3px 0px #000000;
    font-weight: 600;
}

    .ue-secondary-button:hover {
        background-color: white;
        -webkit-box-shadow: 0px 0px 0px #000000;
        box-shadow: 0px 0px 0px 0px #000000;
    }


.ue-link {
    cursor: pointer;
    font-weight: 700;
}

.ue-subject-button {
    margin-right: 24px;
    margin-bottom: 24px;
}

.ue-yellow-background {
    background-color: #FFF5B9;
}

.ue-orange-background {
    background-color: #FC9E36;
}

.ue-light-blue-background {
    background-color: #CDEBEA;
}

.ue-mid-blue-background {
    background-color: #6CEEEF;
}

.ue-teal-background {
    background-color: #2CC0DB;
}

.ue-white-background {
    background-color: white;
}

.ue-subheading {
    margin-top: 48px;
    margin-bottom: 24px;
    font-family: "KoHo", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.ue-prep-heading{
    margin-left: 12px;
}

.ue-get-started-text {
    text-align: center;
    position: relative;
    margin: 48px auto;
}

.ue-get-started-link {
    color: #FC9E36;
    cursor: pointer;
}

.ue-page-container {
    width: 100%;
    background-color: white;
    border-radius: 24px;
    padding: 0;
    border: 2px solid black;
}

.ue-section-text {
    margin: 24px;
}

@media all and (max-width: 750px) {
    .ue-main-content {
        padding: 6px;
    }
}