.ue-cg-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    padding: 24px;
    margin-bottom: 24px;
}

.uc-cg-card {
    display: flex;
    flex-direction: row;
    border: solid 2px;
    border-radius: 24px;
    padding: 12px 24px;
}

.uc-cg-card-border-1 {
    border-color: #6CEEEF;
}

.uc-cg-card-border-2 {
    border-color: #FC9E36;
}

.uc-cg-card-border-3 {
    border-color: #FFF5B9;
}

.uc-cg-card-border-4 {
    border-color: #2CC0DB;
}

.uc-cg-icon {
    font-size: 2em;
    color: #2CC0DB;
    margin-top: 12px;
    width: 32px;
    flex-shrink: 0;
}

.uc-cg-icon-color-1 {
    color: #6CEEEF;
}

.uc-cg-icon-color-2 {
    color: #FC9E36;
}

.uc-cg-icon-color-3 {
    color: #FFF5B9;
}

.uc-cg-icon-color-4 {
    color: #2CC0DB;
}
.uc-cg-copy {
    margin-left: 24px;
}

.uc-cg-title {

}

.uc-cg-text {

}

@media all and (max-width: 800px) {
    .ue-cg-container {
        grid-template-columns: 1fr;
    }
}