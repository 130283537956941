.ue-number-box {
    margin: 48px 0;
    padding: 0 24px 24px 24px;
}

.ue-number-box-title {
    padding-left: 24px;
}

.ue-nb2-inner {
    padding: 24px;
    border-radius: 24px;
    background-color: white;
}

.ue-nb2-row {
    display: flex;
    position: relative;
    width: 100%;
}

.ue-nb2-number {
    width: 24px;
    height: 24px;
    border-radius: 20px;
    border: 2px solid black;
    padding: 10px;
    margin: 12px auto;
    text-align: center;
    flex-shrink: 0;
}

.ue-nb2-item {
    flex-grow: 1;
    margin: 24px 0 12px 24px;
}
