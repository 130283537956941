.ue-theme-box {
	padding: 24px 36px;
	position: relative;
	border-radius: 24px 24px 24px 0;
	border: 2px solid black;
	margin: 24px 0 0 0;
	z-index: 1;
}

.ue-theme-box-title {
	text-align: left;
	width: 100%;
	margin: 0 0 12px 0;
	font-family: "KoHo", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.ue-theme-box-text {
	margin: 24px 0 24px 0;
}

.ue-topic-overview-box {
	display: flex;
	position: relative;
	width: 100%;
	margin: 0;
	z-index: 2;
}

.ue-topic-list-element-first {
	padding: 16px;
	width: 60px;
	flex-shrink: 0;
	border-width: 2px 2px 0 2px;
	border-style: solid;
	border-color: black;
}

.ue-topic-list-element {
	padding: 16px;
	width: 60px;
	flex-shrink: 0;
	border-width: 0 2px 0 2px;
	border-style: solid;
	border-color: black;
}

.ue-topic-list-end {
	border-radius: 0 0 0 24px;
	border-width: 0 2px 2px 2px;
}

.ue-topic-index {
	width: 24px;
	height: 24px;
	border-radius: 20px;
	border: 2px solid black;
	padding: 10px;
	box-shadow: inset 2px 2px 0 black;
	margin: 20px auto;
	text-align: center;
}

.ue-topic-overview-details {
	display: flex;
	background-color: white;
	border-radius: 0 24px 24px 0;
	padding: 24px;
	flex-grow: 1;
	margin: 24px 0 0 2px;
}

.ue-topic-overview-details-last {
	margin-bottom: 0; 
}

.ue-topic-button {
	margin: auto 12px 12px 12px;
	min-width: 140px;
	max-height: 48px;
}

.ue-topic-overview-title {
	text-align: left;
	width: 100%;
	font-family: "KoHo", sans-serif;
	font-weight: 700;
	font-style: normal;
	margin: 0 0 24px 0;
}

@media all and (max-width: 750px) {
	.ue-topic-overview-details {
		flex-direction: column;
		align-items: stretch;
	}
}