.ue-hero-container {
    border-radius: 24px 24px 0 0;
    margin-bottom: 48px;
    display: grid;
    grid-template-columns: 60% 40%;
    grid-gap: 24px;
}

.ue-hero-column {
    padding: 0;
    margin: 0;
    order: 1;
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-between;
}

.ue-hero-title-container {
    border-radius: 24px 0 24px 0;
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: black;
    -webkit-box-shadow: 4px 4px 0px 5px #000000;
    box-shadow: 4px 4px 0px 5px #000000;
    padding: 0 24px;
    margin: 0;
}

.ue-hero-image-large {
    width: calc(100% - 48px);
    padding: 24px;
    order: 2;
}

.ue-hero-image-small {
    width: calc(70% - 48px);
    padding: 24px;
    margin: 0 auto;
    order: 2;
}

@media all and (max-width: 750px) {
    .ue-hero-container {
        grid-template-columns: 100%;
    }

    .ue-hero-title-container {
        border-radius: 24px 24px 0 0;
    }

    .ue-hero-title {
        font-size: 2.4em;
    }

    .ue-hero-image-large {
        width: calc(70% - 48px);
        margin: 0 auto;
        padding: 0;
    }
}

@media all and (max-width: 500px) {
    .ue-hero-container {
        grid-template-columns: 100%;
    }

    .ue-hero-title-container {
        border-radius: 24px 24px 0 0;
    }

    .ue-hero-title {
        font-size: 2em;
    }
}

.ue-hero-text {
    padding: 24px 24px 0 24px;
}

.ue-hero-signup {
    padding: 24px 24px 0 24px;
}

.ue-hero-button {
    width: 100%;
}