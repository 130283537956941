.ue-topic-box {
    padding: 24px 36px;
    position: relative;
    border-radius: 24px 24px 24px 0;
    border: 2px solid black;
    margin: 24px 0 0 0;
    z-index: 1;
}

.ue-topic-box-title {
    text-align: left;
    width: 100%;
    margin: 0 0 12px 0;
    font-family: "KoHo", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.ue-topic-box-text {
    margin: 24px 0 24px 0;
}

.ue-activity-overview-box {
    display: flex;
    position: relative;
    width: 100%;
    margin: 0;
    z-index: 2;
}

.ue-activity-list-element-first {
    padding: 16px;
    width: 60px;
    flex-shrink: 0;
    border-width: 2px 2px 0 2px;
    border-style: solid;
    border-color: black;
}

.ue-activity-list-element {
    padding: 16px;
    width: 60px;
    flex-shrink: 0;
    border-width: 0 2px 0 2px;
    border-style: solid;
    border-color: black;
}

.ue-activity-list-end {
    border-radius: 0 0 0 24px;
    border-width: 0 2px 2px 2px;
}

.ue-activity-index {
    width: 24px;
    height: 24px;
    border-radius: 20px;
    border: 2px solid black;
    padding: 10px;
    box-shadow: inset 2px 2px 0 black;
    margin: 20px auto;
    text-align: center;
}

.ue-activity-overview-details {
    display: flex;
    background-color: white;
    border-radius: 0 24px 24px 0;
    flex-grow: 1;
    margin: 24px 0 0 2px;
}

.ue-activity-overview-description {
    padding: 24px;
}

.ue-activity-overview-details-last {
    margin-bottom: 0;
}

.ue-activity-button {
    margin: auto 12px 12px 12px;
    min-width: 180px;
    max-height: 48px;
}

.ue-activity-overview-endstop {
    padding: 24px;
    border-radius: 0 24px 24px 0;
    border: 2px solid #2CC0DB;
    display: flex;
    flex-direction: column;
}

.ue-activity-overview-title {
    text-align: left;
    width: 100%;
    font-family: "KoHo", sans-serif;
    font-weight: 700;
    font-style: normal;
    margin: 0 0 24px 0;
}

.ue-resources-needed {
    color: #013C59;
    font-weight: 300;
}

.ue-user-suitability {
    color: #013C59;
    font-weight: 500;
}

.ue-topic-user-box {
    display: flex;
    flex-direction: row;
    padding-top: 12px;
    padding-bottom: 24px;
}

.ue-topic-user-adult-icon {
    font-size: 2.6em;
    margin-top: auto;
}

.ue-topic-user-child-icon {
    font-size: 2.1em;
    margin-top: auto;
}

.ue-topic-user-label {
    margin: auto 0 0 12px;
}

@media all and (max-width: 750px) {
    .ue-activity-overview-details {
        flex-direction: column;
        align-items: stretch;
    }

    .ue-activity-overview-endstop {
        border-radius: 0 0 24px 0;
    }

    .ue-activity-list-element {
        width: 46px;
    }

    .ue-activity-list-element-first {
        width: 46px;
    }
}
