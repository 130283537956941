.ue-navbar {
    padding: 24px;
    border-radius: 0 0 24px 24px;
    border-width: 0px 2px 2px 2px;
    border-style: solid;
    border-color: black;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-brand {
    display: flex; /* Align the logo and the title side by side */
    align-items: center; /* Center them vertically */
}

.ue-navbar-image {
    height: 60px;
    width: auto;
}

.ue-navbar-title {
    margin: 0 0 0 24px;
}

.ue-navbar-title {
    font-size: 2.5em;
    font-family: "KoHo", sans-serif;
    font-weight: 900;
    font-style: normal;
}

@media all and (max-width: 750px) {
    .ue-navbar-title {
        font-size: 2em;
    }
}

@media all and (max-width: 500px) {
    .ue-navbar-title {
        display: none;
    }
}