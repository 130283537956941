.ueFooter {
    padding: 24px;
    border-radius: 24px 24px 0 0;
    border-width: 2px 2px 0px 2px;
    border-style: solid;
    border-color: black;
    background-color: white;
    bottom: 5%;
}

