.ue-dropdown {
    position: relative;
    display: inline-block;
}

.ue-dropdown-button {
    padding: 10px;
    background-color: #2CC0DB;
    border: 2px solid black;
    cursor: pointer;
    border-radius: 24px;
}

.ue-dropdown-list {
    position: absolute;
    top: 116%;
    right: 0;
    z-index: 1;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #FFF5B9;
    border: 1px solid black;
    min-width: 150px;
    border-radius: 24px;
}

.ue-dropdown-item {
    padding: 18px;
    cursor: pointer;
    border-radius: 24px;
}

    .ue-dropdown-item:hover {
        background-color: #fff;
    }
