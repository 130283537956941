.ue-fwsh-header {
    display: flex;
    align-items: center;
    padding: 12px;
    position: relative;
    border-width: 2px 0;
    border-style: solid;
    border-color: black;
    box-shadow: 2px 2px 0px 2px #000000;
}

.ue-fw-header-top {
    border-radius: 24px 24px 0 0;
}

.ue-dots-container {
    display: flex;
    align-items: center;
    position: absolute;
    right: 24px;
    top: 16px;
}

.dot {
    height: 16px;
    width: 16px;
    background-color: white;
    border-radius: 50%;
    margin-left: 24px;
    transition: transform 0.2s;
    box-shadow: inset 5px 5px 0px 0px #000000;
    border: 1px solid black;
}

.ue-fwsh-title {
    text-align: left;
    width: 100%;
    margin: 24px 12px 12px 12px;
    font-family: "KoHo", sans-serif;
    font-weight: 700;
    font-style: normal;
}

/* Responsive behavior for the dots */
@media (max-width: 600px) { /* Adjust the max-width as needed for your design */
    .dots-container {
        display: none; /* Hide dots on smaller screens */
    }
}
